import React, { FC } from 'react'
import { IconButton, ListItemIcon, Menu, MenuItem } from '@material-ui/core'
import { Delete, ExitToApp, FileCopy, MoreVert, GetApp } from '@material-ui/icons'

type Props = {
  onRemove?: () => void
  onCopy?: () => void
  onMove?: () => void
  onDownload?: () => void
}

export const Actions: FC<Props> = ({ onRemove, onCopy, onMove, onDownload }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        {onCopy && (
          <MenuItem
            button
            onClick={() => {
              handleClose()
              onCopy?.()
            }}
          >
            <ListItemIcon>
              <FileCopy />
            </ListItemIcon>
            Copy
          </MenuItem>
        )}
        {onMove && (
          <MenuItem
            button
            onClick={() => {
              handleClose()
              onMove?.()
            }}
          >
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            Move
          </MenuItem>
        )}
        {onDownload && (
          <MenuItem
            button
            onClick={() => {
              handleClose()
              onDownload?.()
            }}
          >
            <ListItemIcon>
              <GetApp />
            </ListItemIcon>
            Download
          </MenuItem>
        )}
        {onRemove && (
          <MenuItem
            button
            onClick={() => {
              handleClose()
              onRemove?.()
            }}
          >
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            Delete
          </MenuItem>
        )}
      </Menu>
    </>
  )
}
