import { GridColumn } from 'core/data/v2/data-grid/types'
import React, { useMemo } from 'react'
import { NameCell, LocationCell } from '../components/grid-columns'
import { MediaFolder, MediaListItem } from '../types'
import { formatFileSize } from '../utils'

type Params = {
  onSetFolderFilter: (folderId: number) => void
  foldersData: MediaFolder[]
  search: string
}

export const useGetMediaGridColumns = ({ onSetFolderFilter, foldersData, search }: Params) => {
  return useMemo(() => {
    let columns: GridColumn<MediaListItem>[] = [
      {
        field: 'name',
        type: 'custom',
        cellRenderer: ({ rowData }) => (
          <NameCell onFolderTitleClick={onSetFolderFilter} media={rowData} />
        ),
      },
      {
        field: 'size',
        type: 'custom',
        cellRenderer: ({ rowData }) => {
          if (rowData.type === 'folder') {
            return `${rowData.size} item${rowData.size !== 1 ? 's' : ''}`
          }
          return formatFileSize(rowData.size)
        },
        width: 90,
      },
      {
        field: 'createdBy',
        type: 'relation',
        title: 'Author',
        resource: 'users',
        titleField: (user) => `${user.firstName} ${user.lastName}`,
        sorting: {
          sortingField: 'createdBy.firstName',
        },
        width: 200,
      },
      { field: 'createdAt', type: 'date', title: 'Created at', width: 200 },
      { field: 'updatedAt', type: 'date', title: 'Updated at', width: 200 },
    ]

    if (search) {
      columns = columns.toSpliced(1, 0, {
        field: 'parent',
        type: 'custom',
        cellRenderer: ({ rowData }) => (
          <LocationCell media={rowData} foldersData={foldersData || []} />
        ),
        title: 'Location',
      })
    }

    return columns
  }, [search, onSetFolderFilter, foldersData])
}
