import React, {
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import clsx, { ClassValue } from 'clsx'
import { useQuery, useQueryClient } from 'react-query'
import { Box, debounce, Grid, GridSize, makeStyles, Paper } from '@material-ui/core'
import { Header } from './components/header'
import { TableList } from './components/table-list'
import { Footer } from './components/footer'
import { httpService } from 'core/data'
import { GridColumn, RowSelectionChange, SortModel } from './types'
import { DndContext, DndContextProps } from './features/dnd'
import { DataGridProvider } from './providers/data-grid-provider'
import { DataGridDropFilesConfig, useDropFilesZone } from './features/drop-files'

type GridViewType = 'list' | 'cards'

type QueryOptions = {
  enabled: boolean
}

type ApiCollectionResponse = {
  'hydra:member': Record<string, any>[]
  'hydra:totalItems': number
}

const useStyles = makeStyles((theme) => ({
  column: {
    maxHeight: 'inherit',
  },
  paper: {
    maxHeight: 'inherit',
    height: '100%',
  },
  dropArea: {
    border: '2px dashed transparent',
    transition: 'border 1s ease',
  },
  dropAreaActive: {
    borderColor: theme.palette.primary.main,
  },
}))

type Props = {
  resource: string
  columns: GridColumn[]
  cardComponent?: ReactElement
  defaultViewType?: GridViewType
  leftSidebar?: ReactNode
  rightSidebar?: ReactNode
  filters?: Record<string, any>
  queryOptions?: Partial<QueryOptions>
  selected: number[]
  checkboxSelection?: boolean
  isRowDisabled?: (rowData: any) => boolean
  onRowSelect?: (value: any) => void
  onRowSelectionChange?: RowSelectionChange
  onDoubleClickSelect?: (value: any) => void
  headerTopPanelClassName?: ClassValue
  actionsHolder?: ReactNode
  queryKey?: string
  maxHeight: string
  breadcrumbs?: ReactNode
  columnSizes?: { left?: GridSize; center?: GridSize; right?: GridSize }
  onSearchChange?: (val: string) => void
  onDragEnd?: DndContextProps['onDragEnd']
  dropFilesConfig?: DataGridDropFilesConfig
}

export const DataGrid: FC<Props> = (props) => {
  const {
    resource,
    columns,
    cardComponent,
    filters,
    leftSidebar,
    rightSidebar,
    queryOptions = {},
    selected,
    checkboxSelection,
    isRowDisabled,
    headerTopPanelClassName,
    onRowSelectionChange,
    onDoubleClickSelect,
    actionsHolder,
    queryKey,
    maxHeight,
    breadcrumbs,
    columnSizes,
    onSearchChange,
    onDragEnd,
    dropFilesConfig,
  } = props

  const classes = useStyles()

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(20)
  const [totalItems, setTotalItems] = useState(0)
  const [search, setSearch] = useState('')

  const [debouncedSearch, setDebouncedSearch] = useState('')

  const setDebouncedSearchHandler = useRef(debounce(setDebouncedSearch, 300))

  const searchHandler = useCallback(
    (value: string) => {
      setDebouncedSearchHandler.current(value)
      setSearch(value)
      onSearchChange?.(value)
    },
    [onSearchChange]
  )

  const [sorting, setSorting] = useState<SortModel | null>(null)

  const changeSorting = useCallback((column: GridColumn) => {
    setSorting((prevSorting) => {
      const sortingField = column.sorting?.sortingField ?? column.field

      const isColumnChanged = prevSorting?.field !== sortingField
      const needReset = !isColumnChanged && prevSorting?.sort === 'desc'

      if (needReset) {
        return null
      }

      const direction = prevSorting && !isColumnChanged ? 'desc' : 'asc'
      return { field: sortingField, sort: direction }
    })
  }, [])

  useEffect(() => {
    setPage(1)
  }, [filters, perPage, debouncedSearch])

  const key = [queryKey, 'data-grid', resource, page, perPage, debouncedSearch, filters, sorting]

  const queryClient = useQueryClient()

  const { data, isLoading, isFetching } = useQuery(
    key,
    async () => {
      const params: Record<string, unknown> = {
        page,
        itemsPerPage: perPage,
        search: debouncedSearch,
        ...filters,
      }

      if (sorting) {
        params.order = { [sorting.field]: sorting.sort }
      }

      const newData = await httpService
        .get<ApiCollectionResponse>(`/${resource}`, { params })
        .then((res) => res.data)

      const oldData = queryClient.getQueryData<ApiCollectionResponse>(key)

      if (!oldData) return newData

      const pendingItemsIds = oldData['hydra:member']
        .filter((item) => item.pending)
        .map((item) => item.id)

      if (pendingItemsIds.length === 0) return newData

      newData['hydra:member'] = newData['hydra:member'].map((item) => {
        if (pendingItemsIds.includes(item.id)) {
          return { ...item, pending: true }
        }

        return item
      })

      return newData
    },
    {
      ...queryOptions,
      refetchInterval: (data) => {
        if (!data) return false

        const itemWithPending = data['hydra:member'].find((item) => item.pending)

        if (itemWithPending) {
          return 5_000
        }

        return false
      },
      onSuccess: (data) => {
        setTotalItems(data['hydra:totalItems'])
      },
    }
  )

  const [defaultData] = useState([])
  const listData = data?.['hydra:member'] || defaultData

  const hasCardsView = Boolean(cardComponent)

  /**
   * Rows selection
   */
  const isAllSelected = useMemo(() => {
    return listData.every((item) => selected.includes(item.id))
  }, [listData, selected])

  const hasSelectedInRange = useMemo(() => {
    return listData.some((item) => selected.includes(item.id))
  }, [listData, selected])

  const selectRowHandler = useCallback(
    (id: number) => {
      if (id === -1) {
        let newSelected: number[] = []

        const allIdsInRange = listData.map((item) => item.id)

        if (hasSelectedInRange) {
          newSelected = selected.filter((id) => !allIdsInRange.includes(id))
        } else {
          newSelected = [...selected, ...allIdsInRange]
        }

        onRowSelectionChange?.(newSelected, { rows: listData })
        return
      }
      const newSelected = [...selected]
      const selectedIndex = selected.indexOf(id)

      if (selectedIndex >= 0) {
        newSelected.splice(selectedIndex, 1)
      } else {
        newSelected.push(id)
      }

      const findRowData = listData.find((rowData) => rowData.id === id)

      onRowSelectionChange?.(newSelected, { id, rowData: findRowData })
    },
    [hasSelectedInRange, listData, onRowSelectionChange, selected]
  )

  // Drop files
  const { enabled, isRowEnabled, onDropFiles } = dropFilesConfig || {}
  const { getRootProps, isDragActive } = useDropFilesZone({ disabled: !enabled, onDropFiles })

  const checkIsRowDropEnabled = useCallback(
    (rowData: any) => {
      if (typeof isRowEnabled === 'function') return isRowEnabled(rowData)
      return !!isRowEnabled
    },
    [isRowEnabled]
  )

  return (
    <DataGridProvider selected={selected} listData={listData}>
      <Box maxHeight={maxHeight} height={maxHeight} display="flex" flexDirection="column">
        <Grid
          container
          style={{ maxHeight: 'inherit', flex: 1, height: 'inherit' }}
          spacing={2}
          wrap="nowrap"
        >
          {leftSidebar && (
            <Grid item xs={columnSizes?.left ?? 2} className={classes.column}>
              <Paper className={classes.paper}>{leftSidebar}</Paper>
            </Grid>
          )}
          <Grid
            item
            xs={columnSizes?.center ? columnSizes?.center : rightSidebar ? 7 : 10}
            className={classes.column}
          >
            <Paper className={classes.paper}>
              <Header
                hasCardView={hasCardsView}
                search={search}
                onSearchChange={searchHandler}
                actionsHolder={actionsHolder}
                isLoading={isFetching}
                breadcrumbs={breadcrumbs}
                topPanelClassName={headerTopPanelClassName}
              />
              <DndContext onDragEnd={onDragEnd}>
                <Box
                  height={`calc(100% - 56px - 52px - 55px)`}
                  {...getRootProps()}
                  className={clsx(classes.dropArea, { [classes.dropAreaActive]: isDragActive })}
                >
                  <TableList
                    columns={columns}
                    data={listData}
                    isLoading={isLoading}
                    selected={selected}
                    isAllSelected={isAllSelected}
                    hasSelectedInRange={hasSelectedInRange}
                    checkboxSelection={checkboxSelection}
                    isRowDisabled={isRowDisabled}
                    sorting={sorting}
                    rowDropEnabled={checkIsRowDropEnabled}
                    onSorting={changeSorting}
                    onRowSelect={selectRowHandler}
                    onDoubleClickSelect={onDoubleClickSelect}
                    onDropFiles={dropFilesConfig?.onDropFiles}
                  />
                </Box>
              </DndContext>
              <Footer
                totalItems={totalItems}
                page={page}
                perPage={perPage}
                onPageChange={setPage}
                onPerPageChange={setPerPage}
              />
            </Paper>
          </Grid>
          {rightSidebar && (
            <Grid item xs={columnSizes?.right ?? 3} className={classes.column}>
              <Paper className={classes.paper}>{rightSidebar}</Paper>
            </Grid>
          )}
        </Grid>
      </Box>
    </DataGridProvider>
  )
}
