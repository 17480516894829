/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC } from 'react'
import { Button } from '@material-ui/core'
import { Add } from '@material-ui/icons'

type Props = {
  onFilesUpload?: (files: File[]) => void
  disabled?: boolean
}

export const UploadButton: FC<Props> = ({ disabled, onFilesUpload }) => {
  return (
    <>
      <input
        type="file"
        id="upload-file"
        aria-hidden="true"
        tabIndex={-1}
        multiple
        hidden
        style={{ display: 'none' }}
        disabled={disabled}
        onChange={(e) => {
          const { files } = e.currentTarget
          if (!files) return
          onFilesUpload?.(Array.from(files))
        }}
      />
      <label htmlFor="upload-file">
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          component="span"
          disabled={disabled}
        >
          Add file
        </Button>
      </label>
    </>
  )
}
