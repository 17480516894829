import { useCallback } from 'react'
import { httpService } from 'core/data'
import { AxiosHeaders, AxiosResponse } from 'axios'
import { useNotify } from 'core/hooks'
import { generateBulkIriCollection, HelperStorage } from './use-helper-storage'

type Params = {
  helperStorage: HelperStorage
  selected: number[]
}

export const useDownloadMedia = ({ helperStorage, selected }: Params) => {
  const notify = useNotify()

  const downloadMedia = useCallback(async () => {
    let res: AxiosResponse | null = null

    const toast = notify('Preparing download...', {
      type: 'loading',
    })

    if (selected.length === 1 && helperStorage[selected[0]].type === 'media') {
      const mediaItem = helperStorage[selected[0]]
      res = await httpService.post(`/media/${mediaItem.id}/downloading`, null, {
        responseType: 'blob',
        headers: {
          accept: 'application/octet-stream',
        },
      })
    } else {
      const items = generateBulkIriCollection(selected, helperStorage)

      res = await httpService.post(
        '/media_advanceds/downloading',
        { items },
        {
          responseType: 'blob',
          headers: {
            accept: 'application/octet-stream',
          },
        }
      )
    }

    if (typeof res === 'undefined' || !res) {
      notify('Something went wrong', { type: 'error' })
      toast.close()
      return
    }

    const blob = new Blob([res.data])

    let filename = 'vilcan-cms-media-files'

    if (res.headers instanceof AxiosHeaders) {
      const contentDisposition = res.headers.get('Content-Disposition')?.toString()

      filename = contentDisposition
        ? contentDisposition.split('filename=')[1].replace(/"/g, '')
        : 'downloaded-file'
    }

    const url = window.URL.createObjectURL(blob)

    const link: HTMLAnchorElement = document.createElement('a')
    link.href = url
    link.download = filename
    link.click()
    URL.revokeObjectURL(url)

    toast.close()
    notify('Files succeessfully prepared for download', { type: 'success' })
  }, [helperStorage, notify, selected])

  return {
    downloadMedia,
  }
}
