import React, { FC, useMemo } from 'react'
import {
  Checkbox,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableRowProps,
  TableSortLabel,
} from '@material-ui/core'
import { GridColumn, SortModel } from '../../types'
import { RelationCell } from './relation-cell'
import { DateCell } from './date-cell'
import RowsSkeleton from 'modules/new-entity/components/data-view/rows-skeleton'
import clsx from 'clsx'
import { useDndItemController } from '../../features/dnd'
import { useDropFilesZone } from '../../features/drop-files'

const useStyles = makeStyles((theme) => ({
  rowBase: {
    '&:hover .select-row-checkbox:not(.Mui-checked)': {
      opacity: '1',
    },
    '& .select-row-checkbox:not(.Mui-checked)': {
      opacity: 0,
    },
  },
  row: {
    cursor: 'pointer',
    transition: 'outline .3s ease, opacity .3s ease',
    outline: `1px solid transparent`,
    '&.MuiTableRow-hover:hover': {
      backgroundColor: '#f5f5f5',
    },
    '&.Mui-selected': {
      backgroundColor: '#e7e5ff',
    },
  },
  outlined: {
    outlineColor: theme.palette.primary.main,
    background: '#e7e5ff',
  },
  disabled: {
    opacity: 0.5,
    cursor: 'default',
    pointerEvents: 'none',
  },
  pending: {
    opacity: 0.5,
    cursor: 'wait',
  },
}))

type Props = {
  columns: GridColumn[]
  data: Record<string, any>[]
  isLoading: boolean
  selected: number[]
  checkboxSelection?: boolean
  isRowDisabled?: (rowData: any) => boolean
  isAllSelected: boolean
  hasSelectedInRange: boolean
  sorting: SortModel | null
  rowDropEnabled: (rowData: any) => boolean
  onDropFiles?: (files: File[], rowData?: any) => void
  onSorting: (column: GridColumn) => void
  onRowSelect?: (id: number) => void
  onDoubleClickSelect?: (value: any) => void
}

export const TableList: FC<Props> = ({
  columns,
  data,
  isLoading,
  selected,
  checkboxSelection,
  isRowDisabled,
  isAllSelected,
  hasSelectedInRange,
  sorting,
  rowDropEnabled,
  onSorting,
  onRowSelect,
  onDoubleClickSelect,
  onDropFiles,
}) => {
  return (
    <TableContainer style={{ maxHeight: '100%' }}>
      <Table stickyHeader size="small">
        <TableHeader
          columns={columns}
          isAllSelected={isAllSelected}
          hasSelectedInRange={hasSelectedInRange}
          sorting={sorting}
          onSorting={onSorting}
          onRowSelect={onRowSelect}
          checkboxSelection={checkboxSelection}
        />
        <TableBody>
          {isLoading && <RowsSkeleton columnsCount={columns.length + 1} show />}
          {data.map((rowData) => (
            <TableRowItem
              key={rowData.id}
              rowData={rowData}
              columns={columns}
              selected={selected.includes(rowData.id)}
              checkboxSelection={checkboxSelection}
              selectedRows={selected}
              hover={Boolean(onRowSelect)}
              isRowDisabled={isRowDisabled}
              dropFileEnabled={rowDropEnabled(rowData)}
              onClick={() => onRowSelect?.(rowData.id)}
              onDoubleClick={() => onDoubleClickSelect?.(rowData)}
              onDropFiles={onDropFiles}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

type TableRowItemProps = {
  columns: GridColumn[]
  rowData: any
  selectedRows: number[]
  dropFileEnabled?: boolean
  checkboxSelection?: boolean
  isRowDisabled?: (rowData: any) => boolean
  onDropFiles?: (files: File[], rowData?: any) => void
} & TableRowProps

export const TableRowItem: FC<TableRowItemProps> = ({
  columns,
  rowData,
  selected,
  selectedRows,
  dropFileEnabled,
  hover,
  checkboxSelection,
  isRowDisabled,
  onClick,
  onDoubleClick,
  onDropFiles,
}) => {
  const classes = useStyles()

  const isPending = rowData.pending

  const isDisabled = useMemo(() => {
    if (isRowDisabled) {
      return isRowDisabled(rowData)
    }

    return false
  }, [isRowDisabled, rowData])

  const { setRef, listeners, attributes, isOver, dndActive } = useDndItemController({
    id: rowData.id,
    selected: selectedRows,
    disabledDrop: rowData.type !== 'folder',
    disabledDrag: !selected,
  })

  const { getRootProps, isDragActive } = useDropFilesZone({
    noDragEventsBubbling: true,
    disabled: !dropFileEnabled,
    rowData,
    onDropFiles,
  })

  const { ref, ...rootProps } = getRootProps()

  return (
    <TableRow
      {...listeners}
      {...attributes}
      {...rootProps}
      ref={(el) => {
        setRef(el)
        ref.current = el
      }}
      selected={selected}
      onClick={!isPending ? onClick : undefined}
      onDoubleClick={!isPending ? onDoubleClick : undefined}
      hover={hover}
      className={clsx(classes.rowBase, classes.row, {
        [classes.outlined]: isOver || isDragActive,
        [classes.disabled]: isDisabled || (dndActive && selected),
        [classes.pending]: isPending,
      })}
    >
      {checkboxSelection && (
        <TableCell padding="checkbox">
          <Checkbox
            disabled={isPending}
            className="select-row-checkbox"
            color="primary"
            checked={selected}
          />
        </TableCell>
      )}
      {columns.map((column) => (
        <TableCellRenderer key={column.field} column={column} rowData={rowData} />
      ))}
    </TableRow>
  )
}

type TableHeaderProps = {
  columns: GridColumn[]
  isAllSelected: boolean
  hasSelectedInRange: boolean
  sorting: SortModel | null
  checkboxSelection?: boolean
  onSorting: (column: GridColumn) => void
  onRowSelect?: (id: number) => void
}

const TableHeader: FC<TableHeaderProps> = ({
  columns,
  onRowSelect,
  sorting,
  onSorting,
  isAllSelected,
  hasSelectedInRange,
  checkboxSelection,
}) => {
  const classes = useStyles()

  return (
    <TableHead>
      <TableRow className={classes.rowBase}>
        {checkboxSelection && (
          <TableCell padding="checkbox" width={90}>
            <Checkbox
              indeterminate={!isAllSelected && hasSelectedInRange}
              color="primary"
              onChange={() => onRowSelect?.(-1)}
              checked={hasSelectedInRange}
              className="select-row-checkbox"
            />
          </TableCell>
        )}
        {columns.map((column) => {
          const sortable = !column.sorting?.sortable
          const sortingField = column.sorting?.sortingField ?? column.field

          return (
            <TableCell
              style={{ textTransform: 'capitalize' }}
              key={column.field}
              width={column.width}
            >
              {sortable ? (
                <TableSortLabel
                  onClick={() => onSorting(column)}
                  active={sorting?.field === sortingField}
                  direction={sorting?.sort}
                >
                  {column.title ?? column.field}
                </TableSortLabel>
              ) : (
                <>column.title ?? column.field</>
              )}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

type TableCellRendererProps = {
  column: GridColumn
  rowData: Record<string, any>
}

const TableCellRenderer: FC<TableCellRendererProps> = ({ column, rowData }) => {
  const { type } = column

  const content = useMemo(() => {
    if (type === 'custom') {
      return column.cellRenderer({ rowData })
    }
    if (type === 'relation') {
      return <RelationCell column={column} rowData={rowData} />
    }
    if (type === 'date') {
      return <DateCell column={column} rowData={rowData} />
    }

    return rowData[column.field]
  }, [column, rowData, type])

  return <TableCell>{content}</TableCell>
}
