import React, { FC } from 'react'
import { Box } from '@material-ui/core'
import { SidebarHeader } from '../sidebar-header'
import { MediaIcon } from '../media-icon'
import { Actions } from '../actions'

type Props = {
  onClose: () => void
  selectedCount: number
  onRemove: () => void
  onCopy: () => void
  onMove: () => void
  onDownload: () => void
}

export const BulkSelectedPanel: FC<Props> = ({
  onClose,
  selectedCount,
  onRemove,
  onCopy,
  onMove,
  onDownload,
}) => {
  return (
    <Box display="flex" flexDirection="column" height="inherit">
      <SidebarHeader
        title={`${selectedCount} items selected`}
        onClose={onClose}
        actions={
          <Actions onRemove={onRemove} onCopy={onCopy} onMove={onMove} onDownload={onDownload} />
        }
      />
      <Box p={2}>
        <MediaIcon size="preview" />
      </Box>
    </Box>
  )
}
