import React, { FC, useCallback, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  IconButton,
  makeStyles,
  Paper,
  Slide,
  Typography,
} from '@material-ui/core'
import { Close, Delete, ExitToApp, FileCopy, GetApp } from '@material-ui/icons'
import { useNotify } from 'core/hooks'
import { httpService } from 'core/data'
import { Media } from 'modules/media/types'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: '10vh',
    right: 0,
    left: 0,
    margin: '0 auto',
    width: 'fit-content',
    color: theme.palette.text.secondary,
  },
  counter: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  divider: {
    margin: theme.spacing(0, 2),
  },
  button: {
    color: theme.palette.text.secondary,
  },
}))

type Props = {
  selected: number[]
  onClose: () => void
  onCopy: () => void
  onMove: () => void
  onDelete: () => void
  onDownload: () => void
  selectedMedia?: number | null
  onInsert?: (id: Media) => void
}

export const BulkToolbar: FC<Props> = ({
  selected,
  onClose,
  onCopy,
  onMove,
  onDelete,
  selectedMedia,
  onInsert,
  onDownload,
}) => {
  const classes = useStyles()
  const selectedCount = selected.length
  const isOpen = selectedCount > 0
  const notify = useNotify()

  const [isLoading, setIsLoading] = useState(false)

  const enabledInsert =
    Boolean(onInsert) && Boolean(selectedMedia) && !isLoading && selectedCount === 1

  const insertHandler = async () => {
    if (!enabledInsert || !selectedMedia) return

    try {
      setIsLoading(true)
      const mediaData = await httpService
        .get<Media>(`/media/${selectedMedia}`)
        .then((res) => res.data)

      onInsert?.(mediaData)
    } catch (e) {
      notify('Something went wrong', { type: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  const downloadHandler = useCallback(async () => {
    setIsLoading(true)
    await onDownload()
    setIsLoading(false)
  }, [onDownload])

  return (
    <Slide direction="up" in={isOpen} unmountOnExit>
      <Paper className={classes.root}>
        <Box display="flex" alignItems="center" p={1}>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
          <Typography className={classes.counter}>{selectedCount} selected</Typography>
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <Box mr={2}>
            <Button onClick={onCopy} startIcon={<FileCopy />} className={classes.button}>
              Copy
            </Button>
            <Button onClick={onMove} startIcon={<ExitToApp />} className={classes.button}>
              Move
            </Button>
            <Button
              onClick={downloadHandler}
              startIcon={<GetApp />}
              className={classes.button}
              disabled={isLoading}
            >
              Download
            </Button>
            <Button onClick={onDelete} startIcon={<Delete />} className={classes.button}>
              Delete
            </Button>
          </Box>
          {enabledInsert && (
            <Button variant="contained" color="primary" onClick={insertHandler}>
              Insert
            </Button>
          )}
        </Box>
      </Paper>
    </Slide>
  )
}
