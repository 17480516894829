import { useDropzone } from 'react-dropzone'

export type DataGridDropFilesConfig = {
  enabled?: boolean
  isRowEnabled?: boolean | ((rowData: any) => boolean)
  onDropFiles: (files: File[], rowData?: any) => void
}

type Params = {
  disabled?: boolean
  noDragEventsBubbling?: boolean
  onDropFiles?: (files: File[], rowData?: any) => void
  rowData?: any
}

export const useDropFilesZone = ({
  disabled,
  noDragEventsBubbling,
  onDropFiles,
  rowData,
}: Params = {}) => {
  const { getRootProps, isDragActive } = useDropzone({
    disabled,
    noClick: true,
    noDragEventsBubbling,
    onDrop: (files) => {
      onDropFiles?.(files, rowData)
    },
  })

  return {
    getRootProps,
    isDragActive,
  }
}
