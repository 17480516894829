import React, { FC, useCallback } from 'react'
import { toast } from 'react-toastify'
import { isAxiosError } from 'axios'
import { NavLink } from 'react-router-dom'
import { Icon, List, ListItem, ListItemIcon, ListItemText, Paper } from '@material-ui/core'

type ErrorItem = {
  attribute: string
  type: 'entity' | 'widget'
  entityId?: number
  entityTypeId?: number
  widgetId?: number
  widgetTypeId?: number
}

type MapErrorItem = {
  attributes: string[]
} & Omit<ErrorItem, 'attribute'>

type RelationDeleteError = {
  'hydra:description': ErrorItem[]
  'hydra:title': string
}

const RelationsError: FC<{ data: MapErrorItem[]; msg?: string }> = ({ data, msg }) => {
  const defaultMsg = 'Used by other entities please remove relationship with required fields'

  return (
    <>
      <div style={{ marginBottom: 10 }}>{msg || defaultMsg}</div>
      <List
        dense
        component={Paper}
        style={{ maxHeight: 300, overflow: 'auto', background: 'transparent', color: '#fff' }}
        variant="outlined"
      >
        {data.map((item) => {
          const typeId = item.type === 'widget' ? item.widgetTypeId : item.entityTypeId
          const id = item.type === 'widget' ? item.widgetId : item.entityId
          const path = item.type === 'widget' ? 'widgets' : 'entities'
          const link = `/${path}/${typeId}/edit/${id}`
          const text = `${window.location.origin}${link}`
          const secondary = `in: ${item.attributes.join(', ')}`
          return (
            <ListItem
              button
              key={id}
              component={NavLink}
              to={`${link}/?attrs=${item.attributes.join(',')}`}
              target="_blank"
            >
              <ListItemIcon>
                <Icon className="icon-link" />
              </ListItemIcon>
              <ListItemText primary={text} secondary={secondary} />
            </ListItem>
          )
        })}
      </List>
    </>
  )
}

function prepareErrorsData(error: unknown): MapErrorItem[] {
  if (!isAxiosError(error)) {
    throw new Error('Wrong error in deleting entity with relations')
  }

  const errorsData: RelationDeleteError['hydra:description'] =
    error.response?.data['hydra:description']
  const errorsMap: MapErrorItem[] = []

  errorsData.forEach((errorItem) => {
    const errorItemId = errorItem.type === 'widget' ? errorItem.widgetId : errorItem.entityId

    const prevItem = errorsMap.find((item) => {
      const itemId = item.type === 'widget' ? item.widgetId : item.entityId
      return itemId === errorItemId
    })
    if (prevItem) {
      prevItem.attributes = [...prevItem.attributes, errorItem.attribute]
      return
    }
    errorsMap.push({ ...errorItem, attributes: [errorItem.attribute] })
  })

  return errorsMap
}

export function useRelationsError() {
  const getRelationsErrorTitle = (error: unknown) => {
    if (!isAxiosError(error)) return ''
    const errorTitle: string = error.response?.data['hydra:title'] || ''
    return errorTitle
  }

  const isRelationsError = useCallback((error: unknown) => {
    if (!isAxiosError(error)) return false
    const errorTitle = getRelationsErrorTitle(error)
    if (errorTitle.includes('Used by other entities') || error.response?.status === 423) return true
    return false
  }, [])

  const notifyRelationsError = useCallback((errors: unknown, msg?: string) => {
    const errorsData = prepareErrorsData(errors)
    toast(<RelationsError data={errorsData} msg={msg} />, {
      type: 'error',
      autoClose: false,
      containerId: 'toast-big',
      closeOnClick: false,
    })
  }, [])

  return { notifyRelationsError, isRelationsError, getRelationsErrorTitle }
}
