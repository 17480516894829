import React, { useCallback } from 'react'
import { httpService } from 'core/data'
import { MediaFolder, MediaListItem } from '../types'
import { getIdFromIri } from 'core/utils'
import { findDestinationFolder } from '../utils'
import { useNotify } from 'core/hooks'

type Params = {
  onSuccess?: (uploadedIds?: number[]) => void
  foldersData: MediaFolder[]
}

export const useUploadFiles = ({ onSuccess, foldersData }: Params) => {
  const notify = useNotify()

  const isRowDropFileActive = useCallback((rowData: MediaListItem) => {
    return rowData.type === 'folder'
  }, [])

  const uploadFiles = useCallback(
    async (files: File[], folder: number) => {
      const toast = notify('Uploading files', {
        type: 'loading',
      })

      const uploadWorkers = files.map(async (file) => {
        const formData = new FormData()

        if (file.name.endsWith('.svg')) {
          const modifiedBlob = await modifySvg(file)
          formData.append('file', modifiedBlob, file.name)
        } else {
          formData.append('file', file)
        }

        formData.append('folder', `/api/media_folders/${folder}`)

        return httpService
          .post<{ data: { '@id': string } }>(`/media`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then((res) => res.data)
      })

      const results = await Promise.allSettled(uploadWorkers)
      const withSuccess = results.filter((result) => result.status === 'fulfilled')
      const withErrors = results.filter((result) => result.status === 'rejected')

      const folderName = findDestinationFolder(folder, foldersData).name

      toast.close()

      if (withErrors.length > 0) {
        notify(
          <>
            {withErrors.length} of {results.length} weren't uploaded to <b>{folderName}</b>
          </>,
          {
            type: 'error',
          }
        )
      } else {
        const plural = withSuccess.length > 1 ? 's' : ''

        notify(
          <>
            {withSuccess.length} item{plural} uploaded successfully to <b>{folderName}</b>
          </>,
          {
            type: 'success',
          }
        )
      }

      const successIds = withSuccess.map((result) => {
        return +getIdFromIri(result.value['@id'])
      })

      onSuccess?.(successIds)
    },
    [foldersData, notify, onSuccess]
  )

  return {
    isRowDropFileActive,
    uploadFiles,
  }
}

function readFileContent(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      event.target && resolve(event.target.result as string)
    }
    reader.onerror = (error) => {
      reject(error)
    }
    reader.readAsText(file)
  })
}

// Modify SVG file MIME type to "image/svg+xml"
async function modifySvg(file: File) {
  const svgContent = await readFileContent(file)

  const isSvgXml = svgContent.includes('<?xml')
  const hasCorrectXmlns = svgContent.match(
    /<svg[^>]*\sxmlns=(["'])http:\/\/www\.w3\.org\/2000\/svg\1/
  )

  let modifiedSvgContent = svgContent

  // Add XML declaration if missing
  if (!isSvgXml) {
    modifiedSvgContent = `<?xml version="1.0" encoding="utf-8"?>\n${modifiedSvgContent}`
  }

  // Add correct xmlns if missing or incorrect
  if (!hasCorrectXmlns) {
    modifiedSvgContent =
      modifiedSvgContent.replace(
        /<svg([^>]*)\sxmlns=(["'])[^"']*\2/,
        '<svg$1 xmlns="http://www.w3.org/2000/svg"'
      ) || modifiedSvgContent.replace('<svg', '<svg xmlns="http://www.w3.org/2000/svg"')
  }

  return new Blob([modifiedSvgContent], { type: 'image/svg+xml' })
}
