import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { Media } from '../types'
import { useGetMediaFolders } from '../hooks/use-get-media-folders'
import { Box, Button } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { useQueryClient } from 'react-query'
import { MediaFolders } from '../components/media-folders'
import { FolderPreview } from '../components/folder-preview'
import { FilePreview } from '../components/file-preview'
import { DataGrid } from 'core/data/v2/data-grid'
import { useMediaManagerController } from './use-media-manager-controller'
import { Breadcrumbs } from '../components/breadcrumbs'
import { BulkSelectedPanel } from '../components/bulk-selected-panel'
import { useMediaBulkActions } from './use-media-bulk-actions'
import { BulkToolbar } from '../components/bulk-toolbar'
import { useUploadFiles } from './use-upload-files'
import { UploadButton } from '../components/upload-button'
import { useDownloadMedia } from './use-download-media'
import { useMediaBulkActionsHelperStorage } from './use-helper-storage'
import { useGetMediaGridColumns } from './use-get-media-grid-columns'

const QUERY_KEY = 'media-manager-grid'

type Props = {
  maxHeight: string
  onSelectMedia?: (media: Media) => void
  selectedMedia?: number | null
  saveLastSelectedFolder?: boolean
  autoSelectUploadedFile?: boolean
}

export const MediaManager: FC<Props> = ({
  maxHeight,
  onSelectMedia,
  selectedMedia: selectedMediaProp,
  saveLastSelectedFolder,
  autoSelectUploadedFile,
}) => {
  const queryClient = useQueryClient()

  const revalidateData = useCallback(() => {
    return queryClient.invalidateQueries(QUERY_KEY)
  }, [queryClient])

  const {
    foldersData,
    foldersTreeData,
    isLoading,
    defaultFolder,
    refetchFolders,
    setLastSelectedFolder,
  } = useGetMediaFolders({ saveLastSelectedFolder })

  const { helperStorage, setHelperStorageData } = useMediaBulkActionsHelperStorage()

  const {
    selectedRows,
    isBulkSelection,
    bulkPanelOpen,
    selectedMedia,
    folderFilter,
    selectedFolder,
    folderPanelOpen,
    resetState,
    setFolderFilter,
    selectMediaHandler,
    openFolderPanel,
    search,
    setSearch,
    doubleClickHandler,
    rowsSelectionChangeHandler,
    toggleBulkPanel,
  } = useMediaManagerController({
    defaultFolder,
    defaultMedia: selectedMediaProp,
    onRowSelectionChange: setHelperStorageData,
    helperStorage,
  })

  const bulkActionSuccessHandler = useCallback(
    (action: 'delete' | 'copy' | 'move') => {
      if (action !== 'copy') {
        rowsSelectionChangeHandler([], {})
      }
      refetchFolders()
      return revalidateData()
    },
    [refetchFolders, revalidateData, rowsSelectionChangeHandler]
  )

  const { bulkDelete, bulkCopy, bulkMove, dndMoveHandler, FolderSelectModal } = useMediaBulkActions(
    {
      selected: selectedRows,
      helperStorage,
      selectedFolder: folderFilter!,
      onSuccess: bulkActionSuccessHandler,
      queryKey: QUERY_KEY,
      folders: foldersData || [],
    }
  )

  const { isRowDropFileActive, uploadFiles } = useUploadFiles({
    onSuccess: (uploadedIds) => {
      revalidateData()
      if (autoSelectUploadedFile && uploadedIds && uploadedIds.length === 1) {
        selectMediaHandler(uploadedIds[0])
      }
    },
    foldersData: foldersData || [],
  })

  const { downloadMedia } = useDownloadMedia({ helperStorage, selected: selectedRows })

  /**
   * Sync default value folder with folder filter value
   */
  useEffect(() => {
    setFolderFilter(defaultFolder)
  }, [defaultFolder, setFolderFilter])

  /**
   * Save last selected folder to storage
   */
  useEffect(() => {
    if (typeof folderFilter !== 'number') return
    setLastSelectedFolder(folderFilter)
  }, [folderFilter, setLastSelectedFolder])

  /**
   * Refetch folders data after folder filter change
   */
  useEffect(() => {
    refetchFolders()
  }, [folderFilter, refetchFolders])

  const rightSidebar = useMemo(() => {
    if (selectedMedia) {
      return (
        <FilePreview
          mediaId={selectedMedia}
          onClose={resetState}
          onCopy={bulkCopy}
          onMove={bulkMove}
          onDeleteSuccess={() => {
            resetState()
            revalidateData()
          }}
          onUpdateSuccess={() => {
            revalidateData()
          }}
          onDownload={downloadMedia}
        />
      )
    }

    if (folderPanelOpen && typeof folderFilter === 'number') {
      return (
        <FolderPreview
          defaultFolder={folderFilter}
          folderId={selectedFolder}
          onClose={resetState}
          onCopy={bulkCopy}
          onMove={bulkMove}
          onEditSuccess={() => {
            refetchFolders()
            revalidateData()
          }}
          onDeleteSuccess={() => {
            resetState()
            refetchFolders()
            setFolderFilter(defaultFolder)
            revalidateData()
          }}
          onDownload={downloadMedia}
        />
      )
    }

    if (bulkPanelOpen && isBulkSelection) {
      return (
        <BulkSelectedPanel
          onClose={() => toggleBulkPanel(false)}
          selectedCount={selectedRows.length}
          onRemove={bulkDelete}
          onCopy={bulkCopy}
          onMove={bulkMove}
          onDownload={downloadMedia}
        />
      )
    }

    return null
  }, [
    bulkCopy,
    bulkDelete,
    bulkMove,
    bulkPanelOpen,
    defaultFolder,
    folderFilter,
    folderPanelOpen,
    isBulkSelection,
    refetchFolders,
    resetState,
    revalidateData,
    selectedFolder,
    selectedMedia,
    selectedRows.length,
    setFolderFilter,
    toggleBulkPanel,
    downloadMedia,
  ])

  const gridColumns = useGetMediaGridColumns({
    foldersData: foldersData || [],
    search,
    onSetFolderFilter: setFolderFilter,
  })

  const memoFilters = useMemo(() => {
    return {
      folder: !folderFilter && search ? undefined : folderFilter || null,
    }
  }, [folderFilter, search])

  return (
    <>
      <DataGrid
        maxHeight={maxHeight}
        queryKey={QUERY_KEY}
        resource="media_advanceds"
        columns={gridColumns}
        filters={memoFilters}
        queryOptions={{ enabled: typeof folderFilter === 'number' }}
        selected={selectedRows}
        dropFilesConfig={{
          enabled: Boolean(folderFilter),
          isRowEnabled: isRowDropFileActive,
          onDropFiles: (files, rowData) => {
            const folderId = rowData ? rowData.id : folderFilter
            uploadFiles(files, folderId)
          },
        }}
        checkboxSelection
        onRowSelect={selectMediaHandler}
        onRowSelectionChange={rowsSelectionChangeHandler}
        onDoubleClickSelect={doubleClickHandler}
        onSearchChange={setSearch}
        onDragEnd={dndMoveHandler}
        leftSidebar={
          <MediaFolders
            selected={folderFilter}
            onSelect={setFolderFilter}
            data={foldersTreeData || {}}
            isLoading={isLoading}
            onEdit={(id) => openFolderPanel(id)}
          />
        }
        rightSidebar={rightSidebar}
        breadcrumbs={
          <Breadcrumbs
            folder={folderFilter || 0}
            foldersData={foldersData || []}
            onSelect={setFolderFilter}
          />
        }
        actionsHolder={
          <Box style={{ display: 'flex', gap: 16 }}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<Add />}
              onClick={() => openFolderPanel()}
              disabled={!folderFilter}
            >
              Add folder
            </Button>
            <UploadButton
              disabled={!folderFilter}
              onFilesUpload={(files) => uploadFiles(files, folderFilter!)}
            />
          </Box>
        }
      />
      <BulkToolbar
        selected={selectedRows}
        onClose={resetState}
        onDelete={bulkDelete}
        onCopy={bulkCopy}
        onMove={bulkMove}
        selectedMedia={selectedMedia}
        onInsert={onSelectMedia}
        onDownload={downloadMedia}
      />
      {FolderSelectModal}
    </>
  )
}
