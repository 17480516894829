export type SubscriptionEvent = {
  data: string
}

export type EventData<Data = any> = {
  event: string
  data: Data
  metadata: {
    user: string
  }
}

export const parseEvent = <DataType = any>(e: SubscriptionEvent) => {
  const parsedData: EventData<DataType> = JSON.parse(e.data)
  return parsedData
}
