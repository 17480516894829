import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { MediaFolder } from '../types'
import { useMutation, useQueryClient } from 'react-query'
import { httpService } from 'core/data'
import { useConfirmation } from 'core/confirmation'
import { useNotify } from 'core/hooks'
import { isAxiosError } from 'axios'
import cloneDeep from 'lodash.clonedeep'
import { generateBulkIriCollection, HelperStorage } from './use-helper-storage'
import { useEvents } from 'core/events'
import { parseEvent, SubscriptionEvent } from 'core/events/utils'
import { FolderSelectModal } from './folder-select-modal'
import { useAppContext } from 'core/app'
import { findDestinationFolder } from '../utils'

export type MutationPayload = {
  folderId?: number
  action: 'delete' | 'copy' | 'move'
}

type BulkOperationRes = {
  data: { '@id': string }
}

type BulkOperationEvent = {
  message: string
  operationId: string
  messageParameters: {
    count: number
    destinationFolder?: string
  }
}

const getActionStartMessage = (count: number, action: MutationPayload['action']) => {
  const pluralSymbol = count > 1 ? 's' : ''

  const actionNames: Record<MutationPayload['action'], string> = {
    delete: 'Deleting',
    copy: 'Copying',
    move: 'Moving',
  }

  return `${actionNames[action]} ${count} item${pluralSymbol}`
}

const getActionByOperationName = (operationName: string): MutationPayload['action'] => {
  if (operationName === 'bulk_copying_completed') {
    return 'copy'
  }
  if (operationName === 'bulk_deletion_completed') {
    return 'delete'
  }
  if (operationName === 'bulk_moving_completed') {
    return 'move'
  }

  throw new Error(`Unresolved operation name ${operationName}`)
}

type GetSuccessMessagesParams = {
  count: number
  action: MutationPayload['action']
  destinationFolderName?: string
  folders: MediaFolder[]
}

const getSuccessMessage = ({
  count,
  action,
  destinationFolderName,
  folders,
}: GetSuccessMessagesParams) => {
  const pluralSymbol = count > 1 ? 's' : ''

  const actionNames: Record<MutationPayload['action'], string> = {
    delete: 'Deleted',
    copy: 'Copied',
    move: 'Moved',
  }

  if (action === 'copy' || action === 'move') {
    return (
      <>
        {actionNames[action]} {count} item{pluralSymbol} successfully to
        <b> {destinationFolderName}</b>
      </>
    )
  }

  return `${actionNames[action]} ${count} item${pluralSymbol} successfully`
}

type Params = {
  selected: number[]
  helperStorage: HelperStorage
  selectedFolder: number
  onSuccess?: (action: MutationPayload['action']) => void
  queryKey: string
  folders: MediaFolder[]
}

export const useMediaBulkActions = ({
  selected,
  helperStorage,
  selectedFolder,
  onSuccess,
  queryKey,
  folders,
}: Params) => {
  const queryClient = useQueryClient()
  const { setConfirmation } = useConfirmation()
  const notify = useNotify()
  const { subscribe } = useEvents()
  const { user } = useAppContext()

  const [activeBulkAction, setActiveBulkAction] = useState<MutationPayload['action'] | null>(null)
  const [bulkActionFolder, setBulkActionFolder] = useState<number>(selectedFolder)

  const mutation = useMutation(
    (payload: MutationPayload) => {
      const { action, folderId } = payload
      const items = generateBulkIriCollection(selected, helperStorage)
      const destination = `/api/media_folders/${folderId}`

      if (action === 'copy') {
        return httpService.post<BulkOperationRes>('/media_advanceds/copying', {
          items,
          destination,
        })
      }
      if (action === 'move') {
        return httpService.post<BulkOperationRes>('/media_advanceds/moving', { items, destination })
      }

      return httpService.post<BulkOperationRes>('/media_advanceds/deletion', { items })
    },
    {
      onSuccess: async (res, payload) => {
        await onSuccess?.(payload.action)

        notify(getActionStartMessage(selected.length, payload.action), {
          type: 'loading',
          toastId: res.data['@id'],
        })

        setActiveBulkAction(null)

        // Experimental optimistic
        if (payload.action === 'delete' || payload.action === 'move') {
          queryClient.setQueriesData(queryKey, (data: any) => {
            if (!data) return undefined
            const clonedData = cloneDeep(data)
            clonedData['hydra:member'] = clonedData['hydra:member'].map((item: any) => {
              if (!selected.includes(item.id)) return item
              return { ...item, pending: true }
            })
            return clonedData
          })
        }
      },
      onError: (e) => {
        if (isAxiosError(e) && e.response?.data?.['hydra:description']) {
          notify(e.response?.data?.['hydra:description'], { type: 'error' })
        } else {
          notify('Something went wrong', { type: 'error' })
        }
      },
    }
  )

  const bulkDelete = useCallback(() => {
    setConfirmation({
      open: true,
      title: 'Delete confirmation',
      content: 'Are you sure want to delete this items?',
      onSuccess: () => mutation.mutate({ action: 'delete' }),
    })
  }, [mutation, setConfirmation])

  const bulkCopy = useCallback(() => {
    setActiveBulkAction('copy')
  }, [])

  const bulkMove = useCallback(() => {
    setActiveBulkAction('move')
  }, [])

  const dndMoveHandler = useCallback(
    (folderId: number | undefined) => {
      if (!folderId) return
      mutation.mutate({ action: 'move', folderId })
    },
    [mutation]
  )

  const folderSelectProps = useMemo(() => {
    const actionLabel = activeBulkAction === 'copy' ? 'Copy' : 'Move'
    const mainActionLabel = `${actionLabel} here`
    const destinationFolderName = bulkActionFolder
      ? findDestinationFolder(bulkActionFolder, folders).name
      : ''
    const title = `${actionLabel} ${selected.length} items to ${destinationFolderName}`
    const mainActionDisabled = activeBulkAction === 'move' && bulkActionFolder === selectedFolder
    const disabledItems = activeBulkAction === 'move' ? selected : []

    const mainActionClickHandler = () => {
      if (!activeBulkAction) return

      setConfirmation({
        open: true,
        title: `${mainActionLabel} confirmation`,
        content: `Are you sure want to ${actionLabel.toLowerCase()} this items?`,
        onSuccess: () => mutation.mutate({ action: activeBulkAction, folderId: bulkActionFolder }),
      })
    }

    return {
      title,
      mainActionLabel,
      mainActionDisabled,
      disabledItems,
      mainActionClickHandler,
    }
  }, [
    activeBulkAction,
    bulkActionFolder,
    folders,
    mutation,
    selected,
    selectedFolder,
    setConfirmation,
  ])

  const selectFolderOpen = Boolean(activeBulkAction)

  useEffect(() => {
    const subscription = subscribe('/api/media_advanceds')

    const listener = (e: SubscriptionEvent) => {
      const eventData = parseEvent<BulkOperationEvent>(e)
      const operationAction = getActionByOperationName(eventData.event)
      const isTriggeredByCurrentUser = eventData.metadata.user === user['@id']

      if (
        isTriggeredByCurrentUser &&
        ['bulk_deletion_completed', 'bulk_moving_completed', 'bulk_copying_completed'].includes(
          eventData.event
        )
      ) {
        notify.toast?.dismiss(eventData.data.operationId)
        notify(
          getSuccessMessage({
            action: operationAction,
            count: eventData.data.messageParameters.count,
            destinationFolderName: eventData.data.messageParameters.destinationFolder,
            folders,
          }),
          { type: 'success' }
        )
        onSuccess?.(operationAction)
      }
    }

    subscription.addEventListener('message', listener)

    return () => {
      subscription.removeEventListener('message', listener)
      subscription.close()
    }
  }, [folders, notify, onSuccess, subscribe, user])

  return {
    bulkDelete,
    bulkCopy,
    bulkMove,
    dndMoveHandler,
    FolderSelectModal: selectFolderOpen ? (
      <FolderSelectModal
        mainActionLabel={folderSelectProps.mainActionLabel}
        initialFolder={selectedFolder}
        open={selectFolderOpen}
        title={folderSelectProps.title}
        mainActionDisabled={mutation.isLoading || folderSelectProps.mainActionDisabled}
        onClose={() => setActiveBulkAction(null)}
        onMainActionClick={folderSelectProps.mainActionClickHandler}
        onFolderSelect={setBulkActionFolder}
        disabledItems={folderSelectProps.disabledItems}
      />
    ) : null,
  }
}
