import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { MediaListItem } from '../types'
import { useGetMediaFolders } from '../hooks/use-get-media-folders'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogProps,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { MediaFolders } from '../components/media-folders'
import { DataGrid } from 'core/data/v2/data-grid'
import { useMediaManagerController } from './use-media-manager-controller'
import { Breadcrumbs } from '../components/breadcrumbs'
import { useGetMediaGridColumns } from './use-get-media-grid-columns'

const QUERY_KEY = 'media-manager-grid'

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(2, 2),
    height: `calc(100% - ${theme.spacing(4)}px)`,
  },
  header: {
    flexDirection: 'row-reverse',
  },
}))

type Props = {
  initialFolder: number
  title: string
  mainActionLabel: string
  mainActionDisabled: boolean
  disabledItems: number[]
  onMainActionClick: () => void
  onFolderSelect: (folderId: number) => void
} & DialogProps

export const FolderSelectModal: FC<Props> = ({
  initialFolder,
  mainActionLabel,
  open,
  title,
  mainActionDisabled,
  disabledItems,
  onMainActionClick,
  onFolderSelect,
  onClose,
}) => {
  const classes = useStyles()

  const { foldersData, foldersTreeData, isLoading } = useGetMediaFolders()

  const {
    selectedRows,
    folderFilter,
    setFolderFilter,
    openFolderPanel,
    search,
    setSearch,
    doubleClickHandler,
  } = useMediaManagerController({
    defaultFolder: initialFolder,
  })

  useEffect(() => {
    if (!folderFilter) return
    onFolderSelect(folderFilter)
  }, [folderFilter, onFolderSelect])

  const gridColumns = useGetMediaGridColumns({
    foldersData: foldersData || [],
    search,
    onSetFolderFilter: setFolderFilter,
  })

  const memoFilters = useMemo(() => {
    return {
      folder: !folderFilter && search ? undefined : folderFilter || null,
    }
  }, [folderFilter, search])

  const checkIsRowDisabled = useCallback(
    (rowData: MediaListItem) => {
      if (rowData.type === 'media') {
        return true
      }
      return disabledItems.includes(rowData.id)
    },
    [disabledItems]
  )

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="body"
      classes={{ paper: classes.paper }}
      fullWidth
      maxWidth="lg"
    >
      <Box p={2}>
        <DataGrid
          columnSizes={{ left: 3, center: 9 }}
          maxHeight="calc(100vh - 64px - 53px)"
          queryKey={QUERY_KEY}
          resource="media_advanceds"
          columns={gridColumns}
          filters={memoFilters}
          queryOptions={{ enabled: typeof folderFilter === 'number' }}
          selected={selectedRows}
          onDoubleClickSelect={doubleClickHandler}
          onSearchChange={setSearch}
          isRowDisabled={checkIsRowDisabled}
          headerTopPanelClassName={classes.header}
          leftSidebar={
            <MediaFolders
              selected={folderFilter}
              onSelect={setFolderFilter}
              data={foldersTreeData || {}}
              isLoading={isLoading}
              onEdit={(id) => openFolderPanel(id)}
            />
          }
          breadcrumbs={
            <Breadcrumbs
              folder={folderFilter || 0}
              foldersData={foldersData || []}
              onSelect={setFolderFilter}
            />
          }
          actionsHolder={<Typography variant="h6">{title}</Typography>}
        />
      </Box>
      <DialogActions>
        <Button variant="contained" onClick={() => onClose?.({}, 'backdropClick')}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onMainActionClick}
          disabled={mainActionDisabled}
        >
          {mainActionLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
