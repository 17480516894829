import React, { FC } from 'react'
import { Box, Divider, Grid } from '@material-ui/core'
import { SidebarHeader } from '../sidebar-header'
import { MediaIcon } from '../media-icon'
import { MediaFolder } from '../../types'
import { FolderForm } from './folder-form'
import { useGetOne } from 'core/data/v2/hooks/use-get-one'
import { MutationButton } from '../mutation-button'
import { useMutateOne } from 'core/data/v2/hooks/use-mutate-one'
import { Actions } from '../actions'
import { useDeleteOne } from 'core/data/v2/hooks/use-delete-one'
import { transformLocalizations } from 'core/data'
import { SidebarPanelLoading } from '../sidebar-panel-loading'

type Props = {
  folderId?: number | null
  defaultFolder: number
  onClose: () => void
  onCopy: () => void
  onMove: () => void
  onDownload: () => void
  onDeleteSuccess: () => void
  onEditSuccess: () => void
}

export const FolderPreview: FC<Props> = ({
  folderId,
  defaultFolder,
  onClose,
  onEditSuccess,
  onDeleteSuccess,
  onCopy,
  onMove,
  onDownload,
}) => {
  const isEdit = Boolean(folderId)

  const { data, isLoading } = useGetOne<MediaFolder>('media_folders', folderId!, {
    enabled: isEdit,
  })

  const deleteM = useDeleteOne('media_folders', {
    onSuccess: onDeleteSuccess,
  })

  const folderM = useMutateOne('media_folders', {
    onSuccess: () => {
      onEditSuccess()
      if (!data) {
        onClose()
      }
    },
  })

  const mutateFolder = (values: Partial<MediaFolder>) => {
    folderM.mutate(values)
  }

  if (isLoading) return <SidebarPanelLoading />

  const initialValues: Partial<MediaFolder> = isEdit
    ? transformLocalizations(data)
    : { name: '', parent: `/api/media_folders/${defaultFolder}` }

  const readOnly = data ? !data.parent : false

  return (
    <Box display="flex" flexDirection="column" height="inherit">
      <SidebarHeader
        title={`${isEdit ? `${data?.name}` : 'Create folder'}`}
        onClose={onClose}
        actions={
          isEdit && (
            <Actions
              onRemove={() => deleteM.mutate(data?.id!)}
              onCopy={onCopy}
              onMove={onMove}
              onDownload={onDownload}
            />
          )
        }
      />
      <Box height="100%" flex={1} overflow="auto">
        <Box p={2}>
          <MediaIcon media={{ ...data, type: 'folder' }} size="preview" />
        </Box>
        <Box px={2} py={1}>
          <FolderForm
            key={folderId}
            formId="edit-folder"
            folderData={initialValues}
            onSubmit={mutateFolder}
            disabled={readOnly}
          />
        </Box>
      </Box>
      {!readOnly && (
        <Box mt="auto">
          <Divider />
          <Box p={2}>
            <Grid container justifyContent="flex-end">
              <MutationButton isLoading={folderM.isLoading} form="edit-folder" type="submit">
                {isEdit ? 'Save' : 'Create'}
              </MutationButton>
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  )
}
