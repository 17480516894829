import React, { FC, ReactNode, ReactText, useCallback } from 'react'
import { Box, CircularProgress, makeStyles } from '@material-ui/core'
import { toast, ToastOptions, TypeOptions } from 'react-toastify'
import { CheckCircle, Cancel } from '@material-ui/icons'

type CustomizedOptions = Omit<ToastOptions, 'type'> & {
  type: TypeOptions | 'loading'
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F2F3F4',
    border: '1px solid #E0E3E6',
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.secondary,
    boxShadow: theme.shadows[1],
  },
}))

interface NotifyFunc {
  (text: ReactNode, options: CustomizedOptions): {
    toastId: ReactText
    close: () => void | boolean
    api: typeof toast
  }
  toast?: typeof toast
}

export const useNotify = () => {
  const classes = useStyles()

  const notify = useCallback<NotifyFunc>(
    (text, options) => {
      const { type, ...initialConfig } = options

      let config: ToastOptions = {
        ...initialConfig,
        hideProgressBar: true,
        className: classes.root,
      }

      if (type === 'loading') {
        config = {
          ...config,
          autoClose: false,
          closeButton: false,
          closeOnClick: false,
        }
      }

      const toastId = toast(<Notification type={type}>{text}</Notification>, config)

      return {
        toastId,
        close: () => toast.dismiss(toastId),
        api: toast,
      }
    },
    [classes.root]
  )

  notify.toast = toast

  return notify
}

const Icons: Record<CustomizedOptions['type'], ReactNode> = {
  success: <CheckCircle htmlColor="#4CAF50" fontSize="inherit" />,
  loading: <CircularProgress size={24} />,
  error: <Cancel color="error" fontSize="inherit" />,
  dark: undefined,
  default: undefined,
  info: undefined,
  warning: undefined,
}

const Notification: FC<{ type: CustomizedOptions['type'] }> = ({ type, children }) => {
  return (
    <Box display="flex" alignItems="center" style={{ gap: 8 }}>
      <Box fontSize={24} lineHeight={1} display="inline-flex">
        {Icons[type]}
      </Box>
      <Box flex={1}>{children}</Box>
    </Box>
  )
}
