import { RefObject } from 'react'
import { FormikProps } from 'formik'
import { SortedAttribute } from './attribute'
import { Entity, OneOfAttributeTypes } from '../../../common/types'

export type FormProps = FormikProps<any>

export type FormRef = RefObject<FormProps>

export type WidgetDataRef = {
  formRef: FormikProps<any>
  attributes: SortedAttribute[]
  expandWidget: () => void
}

export type WidgetsDataRef = {
  [widgetId: number]: WidgetDataRef
}

export type AttributeData = {
  '@id': string
  attributeType: { type: OneOfAttributeTypes }
  name: string
  slug: string
  defaultValues: [] | { value: string }
  validationRules: {
    required?: boolean
    length?: { min: number; max: number }
    regex?: string
    range?: { min: number; max: number }
    count?: { min: number; max: number }
  }
  options: AttributeOptions
  hint: string
  attributeEnums?: SelectEnum[]
  entityType: string
  setAttributes?: FormAttribute[]
  entityTypes: string[]
  readonly: boolean
  system: boolean
}

export type AttributeOptions = {
  hidden: boolean
  show_in_sidebar: boolean
  fieldWidth: number
  type: string
  create_entity_button: boolean
  show_links_modal: boolean
  preservation: boolean
  skip_from_copy: boolean
}

export type FormAttribute = {
  id: number
  options: any
  sortOrder: number
  attribute: AttributeData
  nameable: boolean
}

export type TOptions = any | ''

export type SelectEnum = {
  id: number
  name: string
  value: string
  _value?: string
  sortOrder: number
}

export type Value = {
  id?: number
  attribute: string
  value: string
  locale?: string
  type: string
}

export type SetRepeat = {
  id?: number
  attribute: string
  set: string
  values: Value[]
}

export type OriginalValue = {
  id: number
} & Value

export type FormData = {
  id: number
  '@id': string
  entityTypeAttributes: FormAttribute[]
  values: Value[]
  // TODO set type
  entityWidgets: any[]
  originalValues: OriginalValue[]
  options: any
  entity?: Entity
}

export type ApiError = {
  message: string
  propertyPath: string
}

export enum MutationType {
  CREATE = 'create',
  EDIT = 'edit',
}

export enum EavResourceType {
  ENTITY = 'entities',
  WIDGET = 'widgets',
  TEMPLATE = 'templates',
}

export type RemoveSectionId = {
  containerId: string
  sectionId: string
}

export enum ColorScheme {
  CD_RED_BLACK = 'cb_red_black',
  CB_BLACK_NEON = 'cb_black_neon',
  CB_GREEN = 'cb_green',
  CB_RED_BLUE = 'cb_red_blue',
  CB_BLUE_YELLOW = 'cb_blue_yellow',
  BT10 = 'bt10',
  AB = 'ab',
  PS_PINK_TURQUOISE = 'ps_pink_turquoise',
  MANSIONBET = 'mansionbet',
  BT10_ASIA = 'bt10_asia',
  THEME_TWO_BT10 = 'bettingtop10_new',
  THEME_TWO_BT10_VN = 'bt10_vn_new',
  THEME_TWO_BT10_TH = 'bt10_th_new',
  THEME_TWO_CASINO_COM = 'casino_com_new',
  THEME_TWO_CASINO_COM_VN = 'cc_vn_new',
  THEME_TWO_CASINO_COM_TH = 'cc_th_new',
  THEME_TWO_ONLYRACING = 'onlyracing_new',
  THEME_TWO_MANSION_CASINO = 'mansioncs_new',
}

export const THEME_TWO_COLOR_SCHEME: string[] = [
  ColorScheme.THEME_TWO_BT10,
  ColorScheme.THEME_TWO_BT10_VN,
  ColorScheme.THEME_TWO_BT10_TH,
  ColorScheme.THEME_TWO_CASINO_COM,
  ColorScheme.THEME_TWO_CASINO_COM_VN,
  ColorScheme.THEME_TWO_CASINO_COM_TH,
  ColorScheme.THEME_TWO_ONLYRACING,
  ColorScheme.THEME_TWO_MANSION_CASINO,
]

export type AsideComponent = 'preview' | 'history'
