type Colors = {
  [key: string]: {
    [key: string]: string[]
  }
}

export const schemes: Colors = {
  cb_red_black: {
    sticky_cta_west_color: ['#B0131D', '#000000'],
    sticky_cta_east_color: ['#818181', '#B0131D', '#000000'],
    color: ['#B0131D', '#000000', '#818181'],
    configuration: ['#B0131D', '#007EF2'],
    button_color: ['#B0131D', '#000000', '#818181'],
    home_ctas_color: ['#B0131D', '#000000'],
    colored_border: [
      '',
      '#B0131D',
      '#000000',
      '#007EF2',
      '#F5B100',
      '#FE7A00',
      '#A500F2',
      '#07C403',
    ],
  },
  cb_black_neon: {
    sticky_cta_west_color: ['#40D11B', '#7E00E0'],
    sticky_cta_east_color: ['#818181', '#40D11B', '#7E00E0'],
    color: ['#40D11B', '#7E00E0', '#818181'],
    configuration: ['#40D11B', '#4086E3'],
    button_color: ['#40D11B', '#7E00E0', '#818181'],
    home_ctas_color: ['#40D11B', '#7E00E0'],
    colored_border: [
      '',
      '#DC000E',
      '#000000',
      '#007EF2',
      '#F5B100',
      '#FE7A00',
      '#A500F2',
      '#07C403',
    ],
  },
  cb_green: {
    sticky_cta_west_color: ['#138708', '#818181'],
    sticky_cta_east_color: ['#818181', '#138708', '#818181'],
    color: ['#138708', '#818181', '#818181'],
    configuration: ['#138708', '#0069F4'],
    button_color: ['#138708', '#737373', '#818181'],
    home_ctas_color: ['#138708', '#737373'],
    colored_border: [
      '',
      '#212121',
      '#000000',
      '#007EF2',
      '#F5B100',
      '#FE7A00',
      '#A500F2',
      '#07C403',
    ],
  },
  cb_red_blue: {
    sticky_cta_west_color: ['#B40606', '#00247D'],
    sticky_cta_east_color: ['#818181', '#B40606', '#00247D'],
    color: ['#B40606', '#00247D', '#818181'],
    configuration: ['#006EFF', '#B40606'],
    button_color: ['#B40606', '#00247D', '#818181'],
    home_ctas_color: ['#B40606', '#00247D'],
    colored_border: [
      '',
      '#B40606',
      '#000000',
      '#007EF2',
      '#F5B100',
      '#FE7A00',
      '#A500F2',
      '#07C403',
    ],
  },
  cb_blue_yellow: {
    sticky_cta_west_color: ['#FFCF00', '#779EFF'],
    sticky_cta_east_color: ['#818181', '#FFCF00', '#779EFF'],
    color: ['#FFCF00', '#779EFF', '#818181'],
    configuration: ['#003CD0', '#00C52B'],
    button_color: ['#FFCF00', '#779EFF', '#818181'],
    home_ctas_color: ['#FFCF00', '#779EFF'],
    colored_border: [
      '',
      '#DC3923',
      '#000000',
      '#007EF2',
      '#F5B100',
      '#FE7A00',
      '#A500F2',
      '#07C403',
    ],
  },
  bt10: {
    sticky_cta_west_color: ['#cf142b', '#00247d'],
    sticky_cta_east_color: ['#ededed', '#cf142b', '#00247d'],
    color: ['#cf142b', '#00247d', '#ededed'],
    configuration: ['#2259A2', '#CF142B'],
    button_color: [
      'linear-gradient(180deg, #FA2828 0%, #CF142B 100%)',
      'linear-gradient(180deg, #2259A2 0%, #0B4593 100%)',
      'linear-gradient(180deg, #DBDBDB 0%, #A9A9A9 100%)',
    ],
    home_ctas_color: [
      'linear-gradient(180deg, #FA2828 0%, #CF142B 100%)',
      'linear-gradient(180deg, #2259A2 0%, #0B4593 100%)',
    ],
    colored_border: [
      '',
      '#00247d',
      '#cf142b',
      '#007ef2',
      '#f5b100',
      '#fe7a00',
      '#a500f2',
      '#07c403',
    ],
  },
  ab: {
    sticky_cta_west_color: ['#fa2828', '#2889fa'],
    sticky_cta_east_color: ['#ededed', '#fa2828', '#2889fa'],
    color: ['#fa2828', '#2889fa', '#ededed'],
    configuration: ['#fa2828', '#00e'],
    button_color: ['#fa2828', '#2889fa', '#ededed'],
    home_ctas_color: ['#fa2828', '#2889fa'],
    colored_border: [
      '',
      '#fa2828',
      '#2a2e30',
      '#007ef2',
      '#f5b100',
      '#fe7a00',
      '#a500f2',
      '#07c403',
    ],
  },
  ps_pink_turquoise: {
    sticky_cta_west_color: ['#CD4587', '#09B3B0'],
    sticky_cta_east_color: ['#818181', '#CD4587', '#09B3B0'],
    color: ['#CD4587', '#09B3B0', '#818181'],
    configuration: ['#004EB5', '#CF142B'],
    button_color: ['#CD4587', '#09B3B0', '#BDBDBD'],
    home_ctas_color: ['#CD4587', '#09B3B0'],
    colored_border: [
      '',
      '#B40606',
      '#000000',
      '#007EF2',
      '#F5B100',
      '#FE7A00',
      '#A500F2',
      '#07C403',
    ],
  },
  mansionbet: {
    sticky_cta_west_color: ['#BC2D2F', '#000000'],
    sticky_cta_east_color: ['#818181', '#BC2D2F', '#000000'],
    color: ['#BC2D2F', '#000000', '#818181'],
    configuration: ['#BC2D2F', '#007EF2'],
    button_color: ['#BC2D2F', '#000000', '#818181'],
    home_ctas_color: ['#BC2D2F', '#000000'],
    colored_border: [
      '',
      '#BC2D2F',
      '#000000',
      '#007EF2',
      '#F5B100',
      '#FE7A00',
      '#A500F2',
      '#07C403',
    ],
  },
  bt10_asia: {
    sticky_cta_west_color: ['#cf142b', '#00247d'],
    sticky_cta_east_color: ['#ededed', '#cf142b', '#00247d'],
    color: ['#cf142b', '#00247d', '#ededed'],
    configuration: ['#2259A2', '#CF142B'],
    button_color: [
      'linear-gradient(180deg, #FA2828 0%, #CF142B 100%)',
      'linear-gradient(180deg, #2259A2 0%, #0B4593 100%)',
      'linear-gradient(180deg, #DBDBDB 0%, #A9A9A9 100%)',
    ],
    home_ctas_color: [
      'linear-gradient(180deg, #FA2828 0%, #CF142B 100%)',
      'linear-gradient(180deg, #2259A2 0%, #0B4593 100%)',
    ],
    colored_border: [
      '',
      '#00247d',
      '#cf142b',
      '#007ef2',
      '#f5b100',
      '#fe7a00',
      '#a500f2',
      '#07c403',
    ],
  },
  bettingtop10_new: {
    sticky_cta_west_color: ['#1E90FF', '#060606'],
    sticky_cta_east_color: ['#FF37AF', '#1E90FF', '#060606'],
    color: ['#1E90FF', '#060606', '#FF37AF'],
    configuration: ['#000000', '#1e90ff'],
    button_color: ['#000000', '#1e90ff', '#ff37af'],
    home_ctas_color: ['#1E90FF', '#FF37AF'],
    colored_border: [''],
  },
  bt10_vn_new: {
    sticky_cta_west_color: ['#1E90FF', '#060606'],
    sticky_cta_east_color: ['#FF37AF', '#1E90FF', '#060606'],
    color: ['#1E90FF', '#060606', '#FF37AF'],
    configuration: ['#000000', '#1e90ff'],
    button_color: ['#000000', '#1e90ff', '#ff37af'],
    home_ctas_color: ['#1E90FF', '#FF37AF'],
    colored_border: [''],
  },
  bt10_th_new: {
    sticky_cta_west_color: ['#1E90FF', '#060606'],
    sticky_cta_east_color: ['#FF37AF', '#1E90FF', '#060606'],
    color: ['#1E90FF', '#060606', '#FF37AF'],
    configuration: ['#000000', '#1e90ff'],
    button_color: ['#000000', '#1e90ff', '#ff37af'],
    home_ctas_color: ['#1E90FF', '#FF37AF'],
    colored_border: [''],
  },
  casino_com_new: {
    sticky_cta_west_color: ['#5F57FD', '#060606'],
    sticky_cta_east_color: ['#202022', '#5F57FD', '#060606'],
    color: ['#5F57FD', '#060606', '#202022'],
    configuration: ['#000000', '#5f57fd'],
    button_color: ['#000000', '#5f57fd', '#5f57fd'],
    home_ctas_color: ['#5F57FD', '#5F57FD'],
    colored_border: [''],
  },
  cc_vn_new: {
    sticky_cta_west_color: ['#5F57FD', '#060606'],
    sticky_cta_east_color: ['#202022', '#5F57FD', '#060606'],
    color: ['#5F57FD', '#060606', '#202022'],
    configuration: ['#000000', '#5f57fd'],
    button_color: ['#000000', '#5f57fd', '#5f57fd'],
    home_ctas_color: ['#5F57FD', '#5F57FD'],
    colored_border: [''],
  },
  cc_th_new: {
    sticky_cta_west_color: ['#5F57FD', '#060606'],
    sticky_cta_east_color: ['#202022', '#5F57FD', '#060606'],
    color: ['#5F57FD', '#060606', '#202022'],
    configuration: ['#000000', '#5f57fd'],
    button_color: ['#000000', '#5f57fd', '#5f57fd'],
    home_ctas_color: ['#5F57FD', '#5F57FD'],
    colored_border: [''],
  },
  onlyracing_new: {
    sticky_cta_west_color: ['#188664', '#060606'],
    sticky_cta_east_color: ['#188664', '#000000', '#CA22DA'],
    color: ['#CA22DA', '#188664', '#000000'],
    configuration: ['#000000', '#188664'],
    button_color: ['#000000', '#188664', '#CA22DA'],
    home_ctas_color: ['#188664', '#CA22DA'],
    colored_border: [''],
  },
  mansioncs_new: {
    sticky_cta_west_color: ['#4e99f8', '#060606'], // sticky cta widget west
    sticky_cta_east_color: ['#4E99F8', '#4e99f8', '#060606'], // sticky cta widget east
    color: ['#4e99f8', '#060606', '#4e99f8'], // static cta widget
    configuration: ['#1b1e1f', '#4e99f8'], //Links Box widget
    button_color: ['#000000', '#4e99f8', '#4e99f8'], //Links Box widget button colors
    home_ctas_color: ['#4e99f8', '#4e99f8'], // hero image CTA/s button colors
    colored_border: [''], // content widget (not used for new themes)
  },
}
