import { useCallback, useRef } from 'react'
import { MediaListItem } from '../types'

export type HelperStorageItem = Pick<MediaListItem, 'id' | 'name' | 'type'>

export type HelperStorage = Record<number, HelperStorageItem>

type SetHelperStorageDataParams = {
  selected: number[]
  rowData?: MediaListItem
  rows?: MediaListItem[]
}

export const useMediaBulkActionsHelperStorage = () => {
  const helperStorage = useRef<HelperStorage>({})

  const setHelperStorageData = useCallback((params: SetHelperStorageDataParams) => {
    const { selected, rowData, rows = [] } = params

    const prevStoredData = Object.keys(helperStorage.current).map(
      (id) => helperStorage.current[+id]
    )

    const combinedSourceData = [...rows, ...prevStoredData]

    if (rowData) {
      combinedSourceData.push(rowData)
    }

    helperStorage.current = selected.reduce<HelperStorage>((acc, id) => {
      const findSourceItem = combinedSourceData.find((item) => item.id === id)

      if (findSourceItem) {
        acc[id] = findSourceItem
      }

      return acc
    }, {})
  }, [])

  return {
    helperStorage: helperStorage.current,
    setHelperStorageData,
  }
}

export function generateBulkIriCollection(selected: number[], helperStorage: HelperStorage) {
  return selected.map((id) => {
    const sourceItem = helperStorage[id]

    if (!sourceItem) throw new Error('Error in generation bulk iris')

    const isFolder = sourceItem.type === 'folder'
    const resource = isFolder ? 'media_folders' : 'media'

    return `/api/${resource}/${id}`
  })
}
