import { useCallback, useReducer, useState } from 'react'
import { MediaListItem } from '../types'
import { HelperStorage } from './use-helper-storage'

type State = {
  selectedRows: number[]
  selectedMedia?: number | null
  selectedFolder?: number | null
  folderPanelOpen?: boolean
  folderFilter?: number | null
  bulkPanelOpen: boolean
}

type SelectMediaItemAction = {
  type: 'select_media_item'
  payload: number
}

type ChangeRowSelectionAction = {
  type: 'change_row_selection'
  payload: { selected: number[]; rowData?: MediaListItem; helperStorage?: HelperStorage }
}

type ResetAction = { type: 'reset' }

type SetFolderAction = { type: 'set_folder_filter'; payload: number | null }

type OpenFolderPanelAction = { type: 'open_folder_panel'; payload: { id?: number } }

type ToggleBulkPanelAction = { type: 'toggle_bulk_panel'; payload: boolean }

type Actions =
  | SelectMediaItemAction
  | ChangeRowSelectionAction
  | ResetAction
  | SetFolderAction
  | OpenFolderPanelAction
  | ToggleBulkPanelAction

function stateReducer(state: State, action: Actions): State {
  const { type } = action

  const newState: State = {
    folderFilter: state.folderFilter,
    selectedRows: state.selectedRows,
    bulkPanelOpen: false,
  }

  if (type === 'select_media_item') {
    return {
      ...newState,
      selectedRows: [action.payload],
      selectedMedia: action.payload,
    }
  }

  if (type === 'change_row_selection') {
    const { payload } = action
    const { selected, rowData, helperStorage } = payload

    const isSelectionReduced = state.selectedRows.length > selected.length
    const needSelectItem = selected.length === 1

    if (rowData && needSelectItem) {
      let sourceItem = rowData

      if (isSelectionReduced) {
        if (!helperStorage) throw new Error('Helper storage should be exist for this functionality')
        sourceItem = helperStorage[selected[0]] as MediaListItem
      }

      const stateField = sourceItem.type === 'folder' ? 'selectedFolder' : 'selectedMedia'
      const isToggle = [state.selectedFolder, state.selectedMedia].includes(sourceItem.id)

      if (sourceItem.type === 'folder') {
        newState.folderPanelOpen = !isToggle
      }

      newState[stateField] = isToggle ? null : sourceItem.id
    } else {
      newState.bulkPanelOpen = true
    }

    newState.selectedRows = selected
  }

  if (type === 'set_folder_filter') {
    return {
      ...state,
      folderFilter: action.payload,
      selectedRows: [],
    }
  }

  if (type === 'open_folder_panel') {
    newState.folderPanelOpen = true
    if (action.payload.id) {
      newState.selectedFolder = action.payload.id
    }
    return newState
  }

  if (type === 'toggle_bulk_panel') {
    return {
      ...state,
      bulkPanelOpen: action.payload,
    }
  }

  if (type === 'reset') {
    return {
      ...newState,
      selectedRows: [],
    }
  }

  return newState
}

type Props = {
  defaultFolder?: number | null
  defaultMedia?: number | null
  helperStorage?: HelperStorage
  onRowSelectionChange?: (params: {
    selected: number[]
    rowData?: MediaListItem
    rows?: MediaListItem[]
  }) => void
}

export const useMediaManagerController = (props: Props = {}) => {
  const { defaultFolder, defaultMedia, helperStorage, onRowSelectionChange } = props
  const [search, setSearch] = useState('')

  const [state, dispatch] = useReducer(stateReducer, {
    selectedRows: [],
    selectedMedia: defaultMedia,
    folderFilter: defaultFolder,
    bulkPanelOpen: false,
  })

  const selectMediaHandler = useCallback(
    (mediaItem: number) => {
      dispatch({ type: 'select_media_item', payload: mediaItem })
      const storageObj: any = { id: mediaItem, type: 'media', name: '' }

      onRowSelectionChange?.({
        selected: [mediaItem],
        rowData: storageObj,
        rows: [storageObj],
      })
    },
    [onRowSelectionChange]
  )

  const resetState = useCallback(() => dispatch({ type: 'reset' }), [])

  const setFolderFilter = useCallback(
    (payload: number | null) => dispatch({ type: 'set_folder_filter', payload }),
    []
  )

  const openFolderPanel = useCallback(
    (id?: number) => dispatch({ type: 'open_folder_panel', payload: { id } }),
    []
  )

  const doubleClickHandler = useCallback(
    (data: MediaListItem) => {
      if (data.type !== 'folder') return
      setFolderFilter(data.id)
    },
    [setFolderFilter]
  )

  const rowsSelectionChangeHandler = useCallback(
    (selected: number[], selectionMeta: { rowData?: MediaListItem; rows?: MediaListItem[] }) => {
      dispatch({
        type: 'change_row_selection',
        payload: { selected, rowData: selectionMeta.rowData, helperStorage },
      })
      onRowSelectionChange?.({ selected, rowData: selectionMeta.rowData, rows: selectionMeta.rows })
    },
    [helperStorage, onRowSelectionChange]
  )

  const toggleBulkPanel = useCallback((val: boolean) => {
    dispatch({ type: 'toggle_bulk_panel', payload: val })
  }, [])

  const isBulkSelection = state.selectedRows.length > 1

  return {
    ...state,
    isBulkSelection,
    search,
    resetState,
    setFolderFilter,
    selectMediaHandler,
    openFolderPanel,
    setSearch,
    doubleClickHandler,
    rowsSelectionChangeHandler,
    toggleBulkPanel,
  }
}
