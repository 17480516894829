import React, { FC, RefObject, useState } from 'react'
import { useMutation } from 'react-query'
import ActionsPopover from 'common/components/actions-popover'
import { useNotify } from 'core/hooks'
import { useGetActiveSite } from 'modules/sites'
import { httpService } from 'core/data'
import { Action } from 'core/data/types/actions'
import { UploadFiles } from './upload-file'

type Props = {
  actionsRef: RefObject<HTMLDivElement>
}

export const Actions: FC<Props> = ({ actionsRef }) => {
  const activeSite = useGetActiveSite()
  const notify = useNotify()

  const [showActions, setShowActions] = useState(false)

  const exportM = useMutation(
    () => {
      return httpService
        .get<Blob>('/redirects_export', {
          responseType: 'blob',
          headers: { Accept: 'text/csv' },
        })
        .then((res) => res.data)
    },
    {
      onSuccess: (data) => {
        notify('File was downloaded successfully', { type: 'success' })
        const objectUrl = URL.createObjectURL(data)
        setShowActions(false)
        const link: HTMLAnchorElement = document.createElement('a')
        const siteName = activeSite?.name.toLowerCase().replaceAll(' ', '_')
        link.href = objectUrl
        link.download = `redirects-${siteName}.csv`
        link.click()
        URL.revokeObjectURL(objectUrl)
      },
      onError: () => {
        notify('Something went wrong', { type: 'error' })
      },
    }
  )

  const [importOpen, setImportOpen] = useState(false)

  const actions: Action[] = [
    {
      name: 'export',
      label: 'Export CSV',
      action: () => exportM.mutate(),
      condition: true,
    },
    {
      name: 'import',
      label: 'Import CSV',
      action: () => {
        setShowActions(false)
        setImportOpen(true)
      },
      condition: true,
    },
  ]

  return (
    <>
      <ActionsPopover
        actions={actions}
        showActions={showActions}
        onShowActions={setShowActions}
        anchorEl={actionsRef.current}
        loading={false}
        aqaAction="open_actions"
      />
      {importOpen && <UploadFiles isOpen={importOpen} onClose={() => setImportOpen(false)} />}
    </>
  )
}
